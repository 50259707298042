import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RentACenter from './components/RentACenter';
import GetItNow from './components/GetItNow';
import HomeChoice from './components/HomeChoice';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/rent-a-center" element={<RentACenter />} />
          <Route path="/get-it-now" element={<GetItNow />} />
          <Route path="/home-choice" element={<HomeChoice />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
