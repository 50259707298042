export const RENTACENTER= 'RENTACENTER';
export const GETITNOW= 'GETITNOW';
export const HOMECHOICE= 'HOMECHOICE';
export const CAREEROPPORTUNITIES= 'CAREEROPPORTUNITIES';


export const ENTERPRISEURL= {
    RENTACENTER: 'https://qa-www.rentacenter.com/',
    GETITNOW: 'https://www.getitnowstores.com',
    HOMECHOICE: 'https://www.homechoicestores.com',
    CAREEROPPORTUNITIES:'https://raccareers.com'
}